export const messages = {
    fr: {
        download_apaym: {
            value: "Faciliter vos paiements, télécharger APAYM",
        },
        text_pourboire: {
            value: "Pourboire",
        },
        text_pourboire2: {
            value: "autre",
        },
        text_fees_payment: {
            value: "Je décide de payer les frais",
        },
        text_fees_forced_payment: {
            value: "Je paie les frais obligatoirement",
        },
        text_fees: {
            value: "Frais",
        },
        text_receive_notif: {
            value: "Recevoir la notification du paiement par",
        },
        text_amount_to_pay: {
            value: "Montant à payer",
        },
        text_search_merchant: {
            value: "Rechercher un marchand ...",
        },
        text_sav_0: {
            value: "Vous avez un souci ?",
        },
        text_sav_1: {
            value: "SAV sur WhatsApp",
        },
        text_sav_2: {
            value: "au",
        },
        text_certified_account: {
            value: "Compte certifié",
        },
        text_visa_qr_waiting: {
            value: "En attente de paiement",
        },
        text_qr_sharing_link: {
            value: "Scannez pour être redirigé sur mon lien de paiement",
        },

        text_header: {
            pay: {
                value: "Payer",
            },
            donation: {
                value: "Don à",
            },
            by: {
                value: "Comme vous voulez",
            },
            by2: {
                value: "Par",
            },
            enterprising: {
                value: "Entreprenant(e)",
            },
            pdci: {
                value: "Payez ici vos adhésions, cotisations et dons",
            },
            collected: {
                value: "Collecté",
            },
            transactions: {
                value: "Transactions",
            },
        },

        text_form: {
            amount: {
                value: "Montant à payer",
            },
            amount_air_france: {
                value: "Montant du billet",
            },
            convert_amount: {
                value: "Convertir",
            },
            limit_amount: {
                value: "Vous pouvez payer un maximum de ",
            },
            amount_to_convert: {
                value: "Montant en devise",
            },
            from_currency: {
                value: "De",
            },
            to_currency: {
                value: "En",
            },
            validate_conversion: {
                value: "Valider",
            },
            grade: {
                value: "Qualité/Grade",
            },
            select_grade: {
                value: "Choisissez une Qualité / Grade",
            },
            delegation: {
                value: "Délégation",
            },
            select_delegation: {
                value: "Choisissez une délégation",
            },
            number: {
                value: "Numéro de téléphone", // Numéro de téléphone du client
            },
            number_air_france: {
                value: "Numéro de téléphone payeur", // Numéro de téléphone du client
            },
            number_patient: {
                value: "Numéro de tél. du patient",
            },
            number_activist: {
                value: "Numéro de tél. du militant / sympathisant",
            },
            number_driver: {
                value: "Numéro de tél. du compte Yango",
            },
            name: {
                value: "Nom", // Nom du client
            },
            name_beneficary: {
                value: "Nom du bénéficiaire", // Nom du bénéficiaire
            },
            birth_date: {
                value: "Date de naissance", // Date de naissance
            },
            name_air_france: {
                value: "Nom payeur", // Nom du client
            },
            name_patient: {
                value: "Nom du patient",
            },
            name_activist: {
                value: "Nom du militant / sympathisant",
            },
            name_driver: {
                value: "Nom du conducteur",
            },
            surname: {
                value: "Prénom(s)", // Prénom du client
            },
            surname_air_france: {
                value: "Prénom(s) payeur", // Prénom du client
            },
            surname_patient: {
                value: "Prénom(s) du patient",
            },
            surname_activist: {
                value: "Prénom(s) du militant / sympathisant",
            },
            surname_optional: {
                value: " (Optionnel)",
            },
            surname_driver: {
                value: "Prénom(s) du conducteur",
            },
            society: {
                value: "Société", // Société du client
            },
            entreprise: {
                value: "Nom de l'entreprise",
            },
            society_patient: {
                value: "Société du patient",
            },
            society_driver: {
                value: "Société du conducteur",
            },
            email: {
                value: "Adresse e-mail", // E-mail du client
            },
            email_patient: {
                value: "E-mail du patient",
            },
            email_activist: {
                value: "E-mail du militant / sympathisant",
            },
            email_driver: {
                value: "E-mail du conducteur",
            },
            reason: {
                value: "Motif du paiement",
            },
            pnr_air_france: {
                value: "Numéro de PNR (numéro de réservation)",
            },
            flying_number_air_france: {
                value: "Numéro Flying Blue",
            },
            select_reason: {
                value: "Choisissez un motif",
            },
            button: {
                value: "Payer maintenant",
            },
            additional_data: {
                value: "Informations supplémentaires",
            },
            optional: {
                value: "(Optionnel)",
            },
            account_number: {
                value: "Numéro de compte titre",
            },
            account_number2: {
                value: "Numéro de matricule ou Numéro de compte titre",
            },
            traded_company: {
                value: "Société côté en bourse",
            },
            list_benef_option: {
                value: "Choisissez le type de bénéficiaire",
            },
            list_benef: {
                value: "Bénéficiaire",
            },
            fullname_benef: {
                value: "Libellé du bénéficiaire",
            },
            customer_index: {
                value: "Indice client",
            },
            fullname_applicant: {
                value: "Nom et Prénom(s) du déposant",
            },
            phone_applicant: {
                value: "Numéro de tél. du déposant",
            },
            postal_address: {
                value: "Adresse postale",
            },
            shares: {
                value: "Nombre d'actions",
            },
            traded_company_selected: {
                value: "Choisissez la société côté en bourse",
            },

            branch_store: {
                value: "Station",
            },

            branch_store_option: {
                value: "Sélectionnez une station",
            },

            transaction_nature: {
                value: "Sélectionnez une réponse",
            },

            transaction_nature2: {
                value: "Nature de la transaction",
            },

            registration_number: {
                value: "Immatriculation",
            },
            car_mark: {
                value: "Marque du véhicule",
            },
            type_service: {
                value: "Types de services",
            },

            service_option: {
                value: "Sélectionnez un type de service",
            },

            apartment_number: {
                value: "Numéro d'appartement",
            },

            hotel_reservation_number: {
                value: "Numéro réservation hôtel",
            },

            remark: {
                value: "Remarque",
            },

            formulas: {
                value: "Formules de tickets",
            },

            sold_out: {
                value: "Épuisé",
            },

            formulas_expired: {
                value: "Expiré",
            },

            branch_formulas_selection: {
                value: "Sélectionnez une formule",
            },

            type_client: {
                value: "Type Client"
            },

            type_client_selection: {
                value: "Sélectionnez un type"
            },

            box_quantity: {
                value: 'Quantité de boites souhaitée'
            },

            nombre_ticket: {
                value: 'Quantité'
            },

            client_vip: {
                value: 'Possédez-vous une CARTE PRIVILÈGE?'
            },

            delivery_address: {
                value: 'Où souhaitez-vous retirer votre commande?'
            },

            numero_police: {
                value: "Numéro de Police"
            },

            numero_quittance: {
                value: "Numéro de Quittance"
            },

            code_apporteur: {
                value: "Code apporteur",
            },

            numero_mobile_apporteur: {
                value: "Nº mobile de l'apporteur"
            },

            etes_vous_l_asssure: {
                value: "Êtes-vous l'assuré?"
            },

            name_assure: {
                value: "Nom de l'assuré"
            },

            last_name_assure: {
                value: "Prénoms(s) de l'assuré"
            },

            telephone_assure: {
                value: "Numéro de téléphone de l'assuré"
            }
        },

        receipt: {
            client: {
                value: "Client",
            },
            enterprise: {
                value: "Entreprise",
            },
            client_air_france: {
                value: "Nom et prénom(s) payeur",
            },
            patient: {
                value: "Patient",
            },
            activist: {
                value: "Militant / Sympathisant",
            },
            society: {
                value: "Société",
            },
            delegation: {
                value: "Délégation",
            },
            contact: {
                value: "Contact",
            },
            method: {
                value: "Mode de paiement",
            },
            amount: {
                value: "Montant",
            },
            amount_air_france: {
                value: "Montant du billet",
            },
            reason: {
                value: "Motif du paiement",
            },
            ref: {
                value: "Référence",
            },
            thank: {
                value: "MERCI",
            },
            transaction: {
                value: "REÇU DE TRANSACTION",
            },
            print: {
                value: "Télécharger le reçu",
            },
            button: {
                value: "Terminer",
            },
            button2: {
                value: "Payer à nouveau",
            },
            authenticity_receipt: {
                value: "Scannez pour vérifier l'authenticité du reçu",
            },
            desired_order: {
                value: "Ordre désiré",
            },
            registration: {
                value: "Immatriculation",
            },
            service: {
                value: "Type de service",
            },
            box_quantity: {
                value: 'Quantité de boites souhaitée'
            },
            client_vip: {
                value: 'Possédez-vous une CARTE PRIVILÈGE?'
            },
            delivery_address: {
                value: 'Où souhaitez-vous retirer votre commande?'
            },

            numero_police: {
                value: "Numéro de police"
            },

            numero_quittance: {
                value: "Numéro de quittance"
            },

            etes_vous_l_asssure: {
                value: "Êtes-vous l'assuré?"
            },

            name_assure: {
                value: "Insured name"
            },

            last_name_assure: {
                value: "Insured last name"
            },

            telephone_assure: {
                value: "Insured Phone number"
            }
        },

        payment_successfully: {
            success: {
                value: "Paiement effectué avec succès",
            },
            by: {
                value: "par",
            },
            reason: {
                value: "Pour",
            },
            receipt: {
                value: "Reçu",
            },
            button: {
                value: "Terminer",
            },
        },

        transaction_paid: {
            text: {
                value: "Votre transaction a échoué!",
            },
            text2: {
                value: "Votre paiement a échoué pour l'une des raisons suivantes :",
            },
            apaym: {
                value: "Retour à l'accueil",
            },
        },

        calculator_mode: {
            text: {
                value: "Calculatrice",
            },
            btn_validate: {
                value: "Valider",
            },
            btn_pay: {
                value: "Payer maintenant",
            },
        },

        payment_modal: {
            secured_by: {
                value: "Sécurisé par",
            },
            change_menu: {
                value: "Changer",
            },
            payment_method: {
                value: "Mode de paiement",
            },
            download_visa_qr: {
                value: "Télécharger le QR code",
            },
            bicici: {
                value: "Carte bancaire",
            },
            apaym_account: {
                value: "Compte APAYM",
            },
            bicici_payment_failure_title: {
                value: "Paiement échoué",
            },
            bicici_payment_failure_details: {
                value:
                    "Vous avez annulé la transaction ou solde insuffisant ou paiement réfusé pour délai dépassé.",
            },
            sharing: {
                value: "Partager ce paiement", //"Laisser quelqu'un payer pour vous"
            },
            sharing_or: {
                value: "OU",
            },
            sharing_2: {
                value: "Payer moi-même",
            },
            sharing_text: {
                value: "Partager ce lien de paiement avec un(e) ami(e)",
            },
            sharing_sous_text: {
                value: "Partager le lien via un réseau social ou copier-le !",
            },
            copy: {
                value: "Copier pour partager",
            },
            visa_qr: {
                text: {
                    value:
                        "Scannez le QR code ci-dessous dans votre application APAYM, Ecobank, ou toute autre application Visa pour compléter le paiement.",
                },
            },
            ecobank_qr: {
                text: {
                    value:
                        "Scannez le QR code ci-dessous dans votre application Ecobank pour compléter le paiement.",
                },
            },
            visa: {
                // text: {
                //     value: "Choisissez le type de votre carte bancaire (Visa ou Mastercard) afin de procéder au paiement."
                // },
                text: {
                    value: "Cliquez sur le bouton pour payer.",
                },
                text_orabank: {
                    value:
                        "Remplissez tous les champs en dessous et cliquez sur le bouton pour payer.",
                },
                button: {
                    value: "Payer par",
                },
                card_holder_name: {
                    value: "Nom du porteur de la carte",
                },
                card_number: {
                    value: "Numéro de la carte",
                },
                expiration_date: {
                    value: "Date d'expiration",
                },
                expiration_month: {
                    value: "Mois",
                },
                expiration_year: {
                    value: "Année",
                },
            },
            om: {
                text: {
                    value:
                        "Cliquez sur Payer par code Orange Money afin d'être redirigé sur la page de paiement Orange Money",
                },
                text2: {
                    value:
                        "Vous avez l'app Orange money installée sur votre téléphone ? Si oui, payez avec l'App. Sinon, cliquez sur Payer par code Orange Money afin d'être redirigé sur la page de Payer par code Orange Money.",
                },
                or_om: {
                    value: "OU",
                },
                payment_failure_title: {
                    value: "Paiement échoué",
                },
                payment_failure_details: {
                    value:
                        "Vous avez annulé la transaction, solde insuffisant, numéro incorrect, mot de passe incorrect, paiement réfusé pour délai dépassé, transaction incomplète ou erreur système.",
                },
                button: {
                    value: "Payer par code Orange Money",
                },
                button2: {
                    value: "Payer avec l'Appli Orange Money",
                },
            },
            wave: {
                text: {
                    value:
                        "Cliquez sur Payer par Wave afin d'être redirigé sur la page de paiement.",
                },
                text_2: {
                    value:
                        "Assurez-vous d'avoir la dernière version de l'application installée sur votre téléphone.",
                },
                payment_failure_title: {
                    value: "Paiement échoué",
                },
                payment_failure_details: {
                    value: "Solde insuffisant ou erreur système.",
                },
                button: {
                    value: "Payer par Wave",
                },
                button_2: {
                    value: "Payer avec l'Appli Wave",
                },
                waiting: {
                    value: "Paiement en attente de validation",
                },
            },
            mtn: {
                text: {
                    value:
                        "Renseignez votre numéro MoMo et validez. Ensuite, rendez-vous sur votre téléphone et suivez les instructions de MTN en composant le ",
                },
                text2: {
                    value: " pour valider le paiement.",
                },
                placeholder: {
                    value: "Votre numéro MoMo",
                },
                payment_pending: {
                    value: "Paiement en attente de validation",
                },
                payment_failure_title: {
                    value: "Paiement échoué",
                },
                payment_failure_details: {
                    value:
                        "Solde insuffisant, numéro incorrect, mot de passe incorrect, transaction incomplète ou erreur système.",
                },
                button: {
                    value: "Payer par MoMo",
                },
            },
            moov: {
                text: {
                    value:
                        "Renseignez votre numéro MOOV Money et validez. Rendez-vous ensuite sur votre téléphone et veuillez suivre les instructions de Moov pour valider le paiement.",
                },
                placeholder: {
                    value: "Votre numéro Moov",
                },
                payment_pending: {
                    value: "En cours de vérification",
                },
                payment_failure_title: {
                    value: "Paiement échoué",
                },
                payment_waiting_1: {
                    value: "Veuillez patienter",
                },
                payment_waiting_2: {
                    value: "Avant vérification de votre transaction",
                },
                payment_waiting_3: {
                    value: "En attente de validation",
                },
                payment_failure_details: {
                    value:
                        "Solde insuffisant, transaction introuvable, transaction interrompue, session échue ou erreur système.",
                },
                button: {
                    value: "Payer par MOOV Money",
                },
                check_status: {
                    value: "Vérifier soi-même",
                },
                ou_check_yourself: {
                    value: "OU",
                },
            },
            tresor: {
                text: {
                    value:
                        "Renseignez votre numéro Tresor Money et validez. Rendez-vous ensuite sur votre application Trésor MONEY et allez dans 'Retraits' pour valider le paiement.",
                },
                placeholder: {
                    value: "Votre numéro Tresor Money",
                },
                payment_pending: {
                    value: "En cours de vérification",
                },
                payment_failure_title: {
                    value: "Paiement échoué",
                },
                payment_waiting_1: {
                    value: "Veuillez patienter",
                },
                payment_waiting_2: {
                    value: "Avant vérification de votre transaction",
                },
                payment_waiting_3: {
                    value: "En attente de validation",
                },
                payment_failure_details: {
                    value:
                        "Solde insuffisant, transaction introuvable, transaction interrompue, session échue ou erreur système.",
                },
                button: {
                    value: "Payer par Tresor Money",
                },
                check_status: {
                    value: "Vérifier soi-même",
                },
                ou_check_yourself: {
                    value: "OU",
                },
            },
            ussd_apaym: {
                text: {
                    value: "Pour obtenir votre code de paiement, composez le ",
                },
                text2: {
                    value: " sur Orange et MTN et suivez les instructions.",
                },
                placeholder: {
                    value: "Numéro de tél. APAYM",
                },
                placeholder2: {
                    value: "Code de paiement",
                },
                button: {
                    value: "Payer par APAYM",
                },
            },
            apaym: {
                text: {
                    value:
                        "Connectez-vous à votre compte APAYM pour afficher vos cartes Visa qui y sont rattachées. Cliquez sur la flèche pour vous connecter.",
                },
                placeholder: {
                    value: "Numéro de tél. APAYM",
                },
                placeholder2: {
                    value: "Code de connexion",
                },
                logout: {
                    value: "Déconnexion",
                },
                choose_card: {
                    value: "Choisir une carte pour payer",
                },
                code_sms: {
                    value: "Renseigner le code reçu par SMS.",
                },
                text_validate_payment_title: {
                    value: "Paiement APAYM",
                },
                text_validate_payment: {
                    value: "Votre carte ",
                },
                text_validate_payment2: {
                    value: " sera débitée de ",
                },
                text_validate_payment3: {
                    value: " pour valider le paiement.",
                },
                text_validate_payment_yes: {
                    value: "Oui, je confirme",
                },
                text_validate_payment_no: {
                    value: "Non",
                },
                sweet_alert_text: {
                    value: "Le lien de paiement a été copié !",
                },
                button: {
                    value: "Connectez-vous",
                },
                captcha: {
                    value: "Veuillez valider le captcha !",
                },
            },
            loyalty_card: {
                value: "Carte de fidélité",
            },
            bonus_offered: {
                value: "Bonus offert : ",
            },
        },

        update_data: {
            update: {
                value: "[modifier]",
            },
            update_title: {
                value: "Mise à jour des informations",
            },
            form: {
                number: {
                    value: "Numéro de téléphone",
                },
                society: {
                    value: "Société",
                },
                fullname: {
                    value: "Nom & prénom",
                },
                s: {
                    value: "(s)",
                },
                email: {
                    value: "Adresse e-mail",
                },
                reason: {
                    value: "Motif du paiement",
                },
                button: {
                    value: "Mettre à jour",
                },
            },
        },

        payment_accepted: {
            value: 'Moyens de paiement acceptés'
        }

    },

    en: {
        download_apaym: {
            value: "Pay easy, download APAYM",
        },
        text_pourboire: {
            value: "Tip",
        },
        text_pourboire2: {
            value: "other",
        },
        text_fees_payment: {
            value: "I decide to pay the fee",
        },
        text_fees_forced_payment: {
            value: "I pay the fees obligatorily",
        },
        text_fees: {
            value: "Fees",
        },
        text_amount_to_pay: {
            value: "Amount to pay",
        },
        text_receive_notif: {
            value: "Receive payment notification by",
        },
        text_search_merchant: {
            value: "Search for a merchant ...",
        },
        text_sav_0: {
            value: "Do you have an issue?",
        },
        text_sav_1: {
            value: "SAV on WhatsApp",
        },
        text_sav_2: {
            value: "at",
        },
        text_certified_account: {
            value: "Certified account",
        },
        text_visa_qr_waiting: {
            value: "Waiting for payment",
        },
        text_qr_sharing_link: {
            value: "Scan to be redirected on my payment link",
        },

        text_header: {
            pay: {
                value: "Pay",
            },
            donation: {
                value: "Donation to",
            },
            by: {
                value: "As you want",
            },
            by2: {
                value: "By",
            },
            enterprising: {
                value: "Entrepreneur",
            },
            pdci: {
                value: "Pay your memberships, dues ans donations here",
            },
            collected: {
                value: "Collected",
            },
            transactions: {
                value: "Transactions",
            },
        },

        text_form: {
            pnr_air_france: {
                value: "Reservation number",
            },
            flying_number_air_france: {
                value: "Flying Blue number",
            },
            amount: {
                value: "Amount to pay",
            },
            amount_air_france: {
                value: "Amount of the ticket",
            },
            convert_amount: {
                value: "Convert",
            },
            amount_to_convert: {
                value: "Currency amount",
            },
            limit_amount: {
                value: "You can pay a maximum of ",
            },
            from_currency: {
                value: "From",
            },
            to_currency: {
                value: "To",
            },
            validate_conversion: {
                value: "Validate",
            },
            grade: {
                value: "Quality / Grade",
            },
            select_grade: {
                value: "Choose one Quality / Grade",
            },
            delegation: {
                value: "Delegation",
            },
            select_delegation: {
                value: "Choose one delegation",
            },
            number: {
                value: "Phone number",
            },
            number_air_france: {
                value: "Phone number payer",
            },
            number_patient: {
                value: "Phone number of patient",
            },
            number_activist: {
                value: "Phone number of activist / sympathizer",
            },
            number_driver: {
                value: "Phone number of Yango account",
            },
            name: {
                value: "Last name",
            },
            name_beneficary: {
                value: "Beneficiary name", // Nom du bénéficiaire
            },
            birth_date: {
                value: "Birthday", // Date de naissance
            },
            name_air_france: {
                value: "Last name payer",
            },
            name_patient: {
                value: "Last name of patient",
            },
            name_activist: {
                value: "Last name of activist / sympathizer",
            },
            name_driver: {
                value: "Last name of driver",
            },
            surname: {
                value: "First name",
            },
            surname_air_france: {
                value: "First name payer",
            },
            surname_patient: {
                value: "First name of patient",
            },
            surname_activist: {
                value: "First name of activist / sympathizer",
            },
            surname_driver: {
                value: "First name of driver",
            },
            surname_optional: {
                value: " (Optional)",
            },
            society: {
                value: "Company",
            },
            entreprise: {
                value: "Name of the company",
            },
            society_patient: {
                value: "Company of patient",
            },
            society_driver: {
                value: "Company of driver",
            },
            email: {
                value: "Email",
            },
            email_patient: {
                value: "Email of patient",
            },
            email_activist: {
                value: "Email ",
            },
            email_driver: {
                value: "Email of driver ",
            },
            reason: {
                value: "Reason for payment",
            },
            select_reason: {
                value: "Choose one reason",
            },
            button: {
                value: "Pay now",
            },
            additional_data: {
                value: "Additional data",
            },
            optional: {
                value: "(Optional)",
            },
            account_number: {
                value: "Title Account number",
            },
            account_number2: {
                value: "Registration number or Title account number",
            },
            traded_company: {
                value: "Publicly traded company",
            },
            list_benef_option: {
                value: "Choose a type of beneficiary",
            },
            list_benef: {
                value: "Beneficiary",
            },
            fullname_benef: {
                value: "Beneficiary's wording",
            },
            customer_index: {
                value: "Customer index",
            },
            fullname_applicant: {
                value: "Fullname of the applicant",
            },
            phone_applicant: {
                value: "Phone number of the applicant",
            },
            postal_address: {
                value: "Postal address",
            },
            shares: {
                value: "Number of shares",
            },
            traded_company_selected: {
                value: "Select the publicly traded company",
            },

            branch_store: {
                value: "Branch",
            },

            branch_store_option: {
                value: "Select one branch",
            },

            transaction_nature2: {
                value: "Nature of the transaction",
            },

            transaction_nature: {
                value: "Select one response",
            },

            registration_number: {
                value: "Registration number",
            },
            car_mark: {
                value: "Car mark",
            },
            type_service: {
                value: "Types of services",
            },

            service_option: {
                value: "Select a type of service",
            },

            apartment_number: {
                value: "Apartment number",
            },

            hotel_reservation_number: {
                value: "Hotel reservation number",
            },

            remark: {
                value: "Remark",
            },

            formulas: {
                value: "Ticket formulas",
            },

            sold_out: {
                value: "Sold out",
            },

            formulas_expired: {
                value: "Expired",
            },

            branch_formulas_selection: {
                value: "Select a formula",
            },

            type_client: {
                value: "Client Type"
            },

            type_client_selection: {
                value: "Select a type"
            },

            box_quantity: {
                value: 'Desired quantity of boxes'
            },

            nombre_ticket: {
                value: 'Desired quantity'
            },

            client_vip: {
                value: 'Are you a preferred customer?'
            },

            delivery_address: {
                value: 'Where do you want to pick up your order?'
            },

            numero_police: {
                value: "Police number"
            },

            numero_quittance: {
                value: "Taxe number"
            },

            code_apporteur: {
                value: "Contributor Code",
            },

            numero_mobile_apporteur: {
                value: "Mobile number of contributor"
            },

            etes_vous_l_asssure: {
                value: "Are you the insured?"
            },

            name_assure: {
                value: "Insured name"
            },

            last_name_assure: {
                value: "Insured last name"
            },

            telephone_assure: {
                value: "Insured Phone number"
            }
        },

        payment_successfully: {
            success: {
                value: "Payment made successfully",
            },
            by: {
                value: "By",
            },
            reason: {
                value: "For",
            },
            receipt: {
                value: "Receipt",
            },
            button: {
                value: "Close",
            },
        },

        transaction_paid: {
            text: {
                value: "Your transaction failed!",
            },
            text2: {
                value: "Your transaction failed for one of following reasons:",
            },
            apaym: {
                value: "Back home",
            },
        },

        calculator_mode: {
            text: {
                value: "Calculator",
            },
            btn_validate: {
                value: "Validate",
            },
            btn_pay: {
                value: "Pay now",
            },
        },

        receipt: {
            client: {
                value: "Customer",
            },
            client_air_france: {
                value: "Fullname payer",
            },
            patient: {
                value: "Patient",
            },
            activist: {
                value: "Activist / Sympathizer",
            },
            society: {
                value: "Company",
            },
            delegation: {
                value: "Delegation",
            },
            contact: {
                value: "Phone number",
            },
            method: {
                value: "Payment method",
            },
            amount: {
                value: "Amount",
            },
            amount_air_france: {
                value: "Amount of the ticket",
            },
            reason: {
                value: "Reason for payment",
            },
            ref: {
                value: "Reference",
            },
            thank: {
                value: "THANK YOU",
            },
            transaction: {
                value: "TRANSACTION RECEIPT",
            },
            print: {
                value: "Download the receipt",
            },
            button: {
                value: "Close",
            },
            button2: {
                value: "Pay again",
            },
            authenticity_receipt: {
                value: "Scan to verify the authenticity of the receipt",
            },
            desired_order: {
                value: "Desired order",
            },
            registration: {
                value: "Registration number",
            },
            service: {
                value: "Type of service",
            },
            box_quantity: {
                value: 'Desired quantity of boxes'
            },

            nombre_ticket: {
                value: 'Desired quantity'
            },

            client_vip: {
                value: 'Are you a preferred customer?'
            },

            delivery_address: {
                value: 'Where do you want to pick up your order?'
            },

            numero_police: {
                value: "Police number"
            },

            numero_quittance: {
                value: "Taxe number"
            },

            etes_vous_l_asssure: {
                value: "Are you the insured?"
            },

            name_assure: {
                value: "Insured name"
            },

            last_name_assure: {
                value: "Insured last name"
            },

            telephone_assure: {
                value: "Insured Phone number"
            }
        },

        payment_modal: {
            secured_by: {
                value: "Secured by",
            },
            change_menu: {
                value: "Change",
            },
            payment_method: {
                value: "Payment method",
            },
            download_visa_qr: {
                value: "Download the QR code",
            },
            bicici: {
                value: "Bank card",
            },
            apaym_account: {
                value: "APAYM account",
            },
            bicici_payment_failure_title: {
                value: "Payment failed",
            },
            bicici_payment_failure_details: {
                value:
                    "You canceled the transaction or Insufficient balance or Payment refused for overdue period. Please, try another payment method.",
            },
            sharing: {
                value: "Share this payment", //"Let someone pay for you"
            },
            sharing_or: {
                value: "OR",
            },
            sharing_2: {
                value: "Pay for myself",
            },
            copy: {
                value: "Copy for sharing",
            },
            sharing_text: {
                value: "Share this payment link with a friend",
            },
            sharing_sous_text: {
                value: "Share the link with a social media or copy it!",
            },
            visa_qr: {
                text: {
                    value:
                        "Scan the QR code below with your mobile app APAYM, Ecobank, or other Visa app to complete the payment.",
                },
            },
            visa: {
                // text: {
                //     value: "Choose the type of your bank card (Visa or Mastercard) to proceed with the payment."
                // },
                text: {
                    value: "Click on the button to proceed to payment.",
                },
                text_orabank: {
                    value:
                        "Fill in all the fields below and click on the button to proceed to payment.",
                },
                button: {
                    value: "Pay with",
                },
                card_holder_name: {
                    value: "Card Holders",
                },
                card_number: {
                    value: "Card Number",
                },
                expiration_date: {
                    value: "Expiration Date",
                },
                expiration_month: {
                    value: "Month",
                },
                expiration_year: {
                    value: "Year",
                },
            },
            om: {
                text: {
                    value:
                        "Click on Pay with Orange Money code payment to be redirected to the Orange Money payment page.",
                },
                text2: {
                    value:
                        "Do you have the Orange money app installed on your phone? If yes, pay with the app. Otherwise, click on Pay with Orange Money code to be redirected to the Orange Money payment page. Please, try another payment method.",
                },
                or_om: {
                    value: "OR",
                },
                button: {
                    value: "Pay with Orange Money code",
                },
                button2: {
                    value: "Pay with Orange Money APP",
                },
                payment_failure_title: {
                    value: "Payment failed",
                },
                payment_failure_details: {
                    value:
                        "You canceled the transaction, Insufficient balance, incorrect number, incorrect password, Payment refused for overdue period, Incomplete transaction or system error.",
                },
            },
            wave: {
                text: {
                    value: "Click on Pay with Wave to be redirected on the payment page.",
                },
                text_2: {
                    value:
                        "Make sure you have the latest version of the application installed on your phone.",
                },
                payment_failure_title: {
                    value: "Payment failed",
                },
                payment_failure_details: {
                    value:
                        "Insufficient balance or system error. Try another payment method, please.",
                },
                button: {
                    value: "Pay with Wave",
                },
                button_2: {
                    value: "Pay with Wave APP",
                },
                waiting: {
                    value: "Payment pending validation.",
                },
            },
            mtn: {
                text: {
                    value:
                        "Enter your MoMo number and confirm. Then, go to your phone and follow MTN's instructions by dialing ",
                },
                text2: {
                    value: " to validate the payment.",
                },
                placeholder: {
                    value: "Your MoMo phone number",
                },
                payment_pending: {
                    value: "Payment awaiting confirmation",
                },
                payment_failure_title: {
                    value: "Payment failed",
                },
                payment_failure_details: {
                    value:
                        "Insufficient balance, incorrect number, incorrect password, Incomplete transaction or system error. Please, try another payment method.",
                },
                button: {
                    value: "Pay with MoMo",
                },
            },
            moov: {
                text: {
                    value:
                        "Enter your Moov number and confirm. Then, go to your phone and follow Moov's instructions to validate the payment.",
                },
                placeholder: {
                    value: "Your Moov phone number",
                },
                payment_pending: {
                    value: "In process of verification",
                },
                payment_failure_title: {
                    value: "Payment failed",
                },
                payment_failure_details: {
                    value:
                        "Insufficient balance, Transaction not found, Transaction interrupted, Session expired or system error. Please, try another payment method.",
                },
                payment_waiting_1: {
                    value: "Please wait",
                },
                payment_waiting_2: {
                    value: "Before checking your transaction",
                },
                payment_waiting_3: {
                    value: "Waiting for your validation",
                },
                button: {
                    value: "Pay with MOOV Money",
                },
                check_status: {
                    value: "Check yourself",
                },
                ou_check_yourself: {
                    value: "OR",
                },
            },
            tresor: {
                text: {
                    value:
                        "Enter your Tresor PAY number and confirm. Then, go to your app on 'Withdrawals' to validate the payment.",
                },
                placeholder: {
                    value: "Your Tresor PAY phone number",
                },
                payment_pending: {
                    value: "In process of verification",
                },
                payment_failure_title: {
                    value: "Payment failed",
                },
                payment_failure_details: {
                    value:
                        "Insufficient balance, Transaction not found, Transaction interrupted, Session expired or system error. Please, try another payment method.",
                },
                payment_waiting_1: {
                    value: "Please wait",
                },
                payment_waiting_2: {
                    value: "Before checking your transaction",
                },
                payment_waiting_3: {
                    value: "Waiting for your validation",
                },
                button: {
                    value: "Pay with Tresor PAY",
                },
                check_status: {
                    value: "Check yourself",
                },
                ou_check_yourself: {
                    value: "OR",
                },
            },
            ussd_apaym: {
                text: {
                    value: "To obtain your payment code, dial ",
                },
                text2: {
                    value: " on Orange and MTN and follow the instructions.",
                },
                placeholder: {
                    value: "APAYM phone number",
                },
                placeholder2: {
                    value: "Payment code",
                },
                button: {
                    value: "Pay with APAYM",
                },
            },
            apaym: {
                text: {
                    value:
                        "Log-in to your APAYM account to view your associated Visa cards. Click on the arrow to Log-in.",
                },
                placeholder: {
                    value: "APAYM phone number",
                },
                placeholder2: {
                    value: "Connection code",
                },
                logout: {
                    value: "Log-out",
                },
                choose_card: {
                    value: "Select one card to pay",
                },
                code_sms: {
                    value: "Enter the code received by SMS.",
                },
                text_validate_payment_title: {
                    value: "APAYM Payment",
                },
                text_validate_payment: {
                    value: "Your card ",
                },
                text_validate_payment2: {
                    value: " will be charged with ",
                },
                text_validate_payment3: {
                    value: " to validate the payment.",
                },
                text_validate_payment_yes: {
                    value: "Yes, I confirm",
                },
                text_validate_payment_no: {
                    value: "No",
                },
                sweet_alert_text: {
                    value: "This payment link has been copied!",
                },
                button: {
                    value: "Log-in",
                },
                captcha: {
                    value: "Please validate the captcha!",
                },
            },
            loyalty_card: {
                value: "Loyalty card",
            },
            bonus_offered: {
                value: "Bonus offered: ",
            },
        },

        update_data: {
            update: {
                value: "[update]",
            },
            update_title: {
                value: "Updating information",
            },
            form: {
                number: {
                    value: "Phone number",
                },
                society: {
                    value: "Company",
                },
                fullname: {
                    value: "Fullname",
                },
                s: {
                    value: "",
                },
                email: {
                    value: "Email address",
                },
                code_apporteur: {
                    value: "Reseller Code",
                },
                reason: {
                    value: "Reason for payment",
                },
                button: {
                    value: "Update data",
                },
            },
        },

        payment_accepted: {
            value: 'Accepted payment methods'
        }
    },

    mal: {
        download_apaym: {
            value: "Ayé Aya san ni nogoya, Ayé APAYM bla aya téléphone kon non",
        },
        text_pourboire: {
            value: "Laadilikan",
        },
        text_pourboire2: {
            value: "dɔ wɛrɛ",
        },
        text_fees_payment: {
            value: "Ne y'a latigɛ ka musakaw sara",
        },
        text_fees_forced_payment: {
            value: "Ne ka kan ka sara sara djagoya",
        },
        text_fees: {
            value: "Kɔrɔw",
        },
        text_amount_to_pay: {
            value: "Hakɛ min ka kan ka sara",
        },
        text_receive_notif: {
            value: "Ka kunnafoni sɔrɔ sarali kan",
        },
        text_search_merchant: {
            value: "Féré kela do gnini ...",
        },
        text_sav_0: {
            value: "Yala gɛlɛya dɔ b’i la wa?",
        },
        text_sav_1: {
            value: "Aw bɛ se ka kuma feereli kɔfɛ baarakɛyɔrɔ la WhatsApp kan",
        },
        text_sav_2: {
            value: "au",
        },
        text_certified_account: {
            value: "Konte sɛgɛsɛgɛlen",
        },
        text_visa_qr_waiting: {
            value: "Sara makɔnɔni",
        },
        text_qr_sharing_link: {
            value: "Scan ka bila ka taa n ka sarali yɔrɔ la",
        },

        text_header: {
            pay: {
                value: "Sara",
            },
            donation: {
                value: "Ka din min ke",
            },
            by: {
                value: "I ba fe gnan min kan",
            },
            by2: {
                value: "Ni a ye",
            },
            enterprising: {
                value: "Yere din min baga",
            },
            pdci: {
                value: "Aw ka tɔndenw, juruw ani bolomafaraw sara yan",
            },
            collected: {
                value: "Lajɛlen don",
            },
            transactions: {
                value: "Jagokɛcogo",
            },
        },

        text_form: {
            amount: {
                value: "Hakɛ min ka kan ka sara",
            },
            convert_amount: {
                value: "Yɛlɛma",
            },
            amount_to_convert: {
                value: "Wari hakɛ",
            },
            limit_amount: {
                value: "I bɛ se ka wari sara min ka ca ni ",
            },
            from_currency: {
                value: "ka",
            },
            to_currency: {
                value: "Kɔnɔ",
            },
            validate_conversion: {
                value: "Ka tiɲɛni kɛ",
            },
            grade: {
                value: "Jogo/Kalanso",
            },
            select_grade: {
                value: "Sugandili kɛ Quality / Grade dɔ la",
            },
            delegation: {
                value: "Delegasiyɔn",
            },
            select_delegation: {
                value: "Aw ka cidenjɛkulu dɔ sugandi",
            },
            number: {
                value: "Telefɔni nimɔrɔ",
            },
            number_patient: {
                value: "ka banabagatɔ  ka Telefɔni nimɔrɔ",
            },
            number_activist: {
                value: "kɛlɛcɛ / hinɛbaga Telefɔni  ka nimɔrɔ",
            },
            number_driver: {
                value: "Telefɔni nimɔrɔ ka bɔ Yango ka jate la",
            },
            name: {
                value: "Tɔgɔ",
            },
            name_beneficary: {
                value: "Beneficiary name", // Nom du bénéficiaire
            },
            birth_date: {
                value: "Birthday", // Date de naissance
            },
            name_patient: {
                value: "Banabagatɔ tɔgɔ",
            },
            name_activist: {
                value: "Waleyakɛla / hinɛbaga tɔgɔ",
            },
            name_driver: {
                value: "Borila tɔgɔ",
            },
            surname: {
                value: "Tɔgɔ fɔlɔ",
            },
            surname_patient: {
                value: "Banabagatɔ tɔgɔ fɔlɔ",
            },
            surname_activist: {
                value: "Waleyakɛla / hinɛbaga tɔgɔ fɔlɔ",
            },
            surname_driver: {
                value: "Borila tɔgɔ fɔlɔ",
            },
            surname_optional: {
                value: " (Wajibi tɛ)",
            },
            society: {
                value: "Tɔn",
            },
            society_patient: {
                value: "Banabagatɔw ka tɔn",
            },
            society_driver: {
                value: "Bɔrikɛla ka  tɔn",
            },
            email: {
                value: "E-mail ladɛrɛsi",
            },
            email_patient: {
                value: "Banabagatɔw ka e-mail",
            },
            email_activist: {
                value: "E-mail ladɛrɛsi ",
            },
            email_driver: {
                value: "Bɔrikɛla ka e-mail ",
            },
            reason: {
                value: "Kun minan wari bɛ sara",
            },
            select_reason: {
                value: "A ye kun dɔ sugandi",
            },
            button: {
                value: "Sara sisan",
            },
            additional_data: {
                value: "Kunnafoni wɛrɛw",
            },
            optional: {
                value: "(Wajibi tɛ)",
            },
            account_number: {
                value: "Title Account number",
            },
            account_number2: {
                value: "Registration number or Title account number",
            },
            traded_company: {
                value: "Publicly traded company",
            },
            list_benef_option: {
                value: "Choose a type of beneficiary",
            },
            list_benef: {
                value: "Beneficiary",
            },
            fullname_benef: {
                value: "Beneficiary's wording",
            },
            customer_index: {
                value: "Customer index",
            },
            fullname_applicant: {
                value: "Fullname of the applicant",
            },
            phone_applicant: {
                value: "Phone number of the applicant",
            },
            postal_address: {
                value: "Postal address",
            },
            shares: {
                value: "Number of shares",
            },
            traded_company_selected: {
                value: "Select the publicly traded company",
            },
            registration_number: {
                value: "Registration number",
            },
            car_mark: {
                value: "Car mark",
            },
            type_service: {
                value: "Types of services",
            },

            service_option: {
                value: "Select a type of service",
            },

            apartment_number: {
                value: "Apartment number",
            },
            code_apporteur: {
                value: "Contributor Code",
            },
        },

        payment_successfully: {
            success: {
                value: "Sara min kɛra ɲin nan",
            },
            by: {
                value: "Ni a ye",
            },
            reason: {
                value: "Sarali kun",
            },
            receipt: {
                value: "Sarali ka sɛbɛn",
            },
            button: {
                value: "Ka laban",
            },
        },

        transaction_paid: {
            text: {
                value: "Jago min tɛ yen walima min sara ni tɛ!",
            },
            apaym: {
                value: "Segin ka na so",
            },
        },

        calculator_mode: {
            text: {
                value: "Jatebɔlan",
            },
            btn_validate: {
                value: "Ka tiɲɛni kɛ",
            },
            btn_pay: {
                value: "Ka tiɲɛni kɛ",
            },
        },

        receipt: {
            client: {
                value: "Sannikɛla",
            },
            patient: {
                value: "Banabagatɔ",
            },
            activist: {
                value: "Waleyakɛla / hinɛbaga",
            },
            society: {
                value: "Tɔn",
            },
            delegation: {
                value: "Cidenjɛkulu",
            },
            contact: {
                value: "Ka wele",
            },
            method: {
                value: "Sarali kɛtchogo",
            },
            amount: {
                value: "Da min bɛ sara",
            },
            reason: {
                value: "Sarali kun",
            },
            ref: {
                value: "Tàyɔrɔ",
            },
            thank: {
                value: "Aw ni tchbɛ",
            },
            transaction: {
                value: "JƐKƐLAW KA SƐBƐN",
            },
            print: {
                value: "Télécharger resipi",
            },
            button: {
                value: "Ka laban",
            },
            button2: {
                value: "Sara tugun",
            },
            authenticity_receipt: {
                value: "Scan kɛ walasa ka resipi tiɲɛni sɛgɛsɛgɛ",
            },
            desired_order: {
                value: "Desired order",
            },
        },

        payment_modal: {
            secured_by: {
                value: "Lakanali kɛra ni",
            },
            change_menu: {
                value: "Ka yɛlɛman",
            },
            payment_method: {
                value: "Sara kɛcogo",
            },
            download_visa_qr: {
                value: "Télécharger le QR code",
            },
            bicici: {
                value: "Banki karti",
            },
            apaym_account: {
                value: "APAYM Konte",
            },
            bicici_payment_failure_title: {
                value: "Sarali ma ɲɛ",
            },
            bicici_payment_failure_details: {
                value:
                    "I ye jago tiɲɛ walima balansi dafalen tɛ walima wari sarali banna ka da a kan a waati tɛmɛna. I ka wari saracogo wɛrɛ lajɛ.",
            },
            sharing: {
                value: "A to Mɔgɔ dɔ ka sara i ye",
            },
            sharing_or: {
                value: "Walaman",
            },
            sharing_2: {
                value: "Ne yɛrɛ ka sara",
            },
            copy: {
                value: "Ka kopi kɛ",
            },
            sharing_text: {
                value: "A ye nin sarali bla ɲɔgɔn man",
            },
            sharing_sous_text: {
                value:
                    "Aw ka jɛgɛnsira tila ɲɔgɔn na réseau sociaux fɛ walima aw k'a kopi kɛ!",
            },
            visa_qr: {
                text: {
                    value:
                        "I ka QR code min bɛ duguma i ka APAYM, Ecobank, walima Visa app wɛrɛ kɔnɔ, i k'o sɛgɛsɛgɛ walasa ka wari sarali dafa.",
                },
            },
            visa: {
                // text: {
                //     value: "Choose the type of your bank card (Visa or Mastercard) to proceed with the payment."
                // },
                text: {
                    value: "Aw ka butɔni digi walasa ka taa ɲɛ ni wari sarali ye.",
                },
                button: {
                    value: "Sara ka kɛ",
                },
            },
            om: {
                text: {
                    value:
                        "I ka Pay by Orange Money code digi walasa ka bila Orange Money sarali ɲɛ kan.",
                },
                text2: {
                    value:
                        "Yala Orange Money app bɛ i bolo i ka telefɔni na wa? N'o don, i ka sara ni App ye. N'o tɛ, i ka Pay by Orange Money code digi walasa ka taa Pay by Orange Money code ɲɛ kan.",
                },
                or_om: {
                    value: "OU",
                },
                button: {
                    value: "Sara ka kɛɲɛ ni Orange Money code ye",
                },
                button2: {
                    value: "Sara ni Orange Money App ye",
                },
                payment_failure_title: {
                    value: "Sarali ma ɲɛ",
                },
                payment_failure_details: {
                    value:
                        "I ye jago tiɲɛ, balansi ma dafa, nimɔrɔ jugu, tɔgɔlasɛbɛn jugu, wari sarali banna waati tɛmɛnen na, jago dafabali walima sistɛmu fili. Aw ye wari saracogo wɛrɛ lajɛ.",
                },
            },
            wave: {
                text: {
                    value: "I ka Pay by Wave digi walasa ka bila sarali ɲɛ kan.",
                },
                text_2: {
                    value:
                        "Aw k'aw jija ka appli (application) kura in bila aw ka telefɔni na.",
                },
                payment_failure_title: {
                    value: "Sarali ma ɲɛ",
                },
                payment_failure_details: {
                    value:
                        "Banlan dafalen walima sistɛmu fili. Aw bɛ wari saracogo wɛrɛ lajɛ.",
                },
                button: {
                    value: "Sara ni Wave ye",
                },
                button_2: {
                    value: "Sara ni Wave APP ye",
                },
                waiting: {
                    value: "Sara min bɛ makɔnɔn ka tiɲɛni kɛ.",
                },
            },
            mtn: {
                text: {
                    value:
                        "I ka MoMo nimɔrɔ dafa ka tiɲɛni kɛ. O kɔfɛ, taa i ka telefɔni na ka MTN ka cikanw labato ni i ye ",
                },
                text2: {
                    value: " ka sarali in tiɲɛ.",
                },
                placeholder: {
                    value: "Aw ka MoMo nimɔrɔ",
                },
                payment_pending: {
                    value: "Sara min bɛ makɔnɔn ka tiɲɛni kɛ",
                },
                payment_failure_title: {
                    value: "Sarali ma ɲɛ",
                },
                payment_failure_details: {
                    value:
                        "Balansi dafabali, nimɔrɔ jugu, tɔgɔlasɛbɛn jugu, jago dafabali walima sistɛmu fili. Aw bɛ wari saracogo wɛrɛ lajɛ.",
                },
                button: {
                    value: "Sara ka bɔ MoMo fɛ",
                },
            },
            moov: {
                text: {
                    value:
                        "I ka Moov wari nimɔrɔ dafa k'a sɛmɛntiya. O kɔfɛ, taa i ka telefɔni na, i ka tugu Moov ka cikanw kɔ walasa ka wari sarali tiɲɛ.",
                },
                placeholder: {
                    value: "I ka Moov nimɔrɔ",
                },
                payment_pending: {
                    value: "Ka sɛgɛsɛgɛli kɛ",
                },
                payment_failure_title: {
                    value: "Sarali ma ɲɛ",
                },
                payment_failure_details: {
                    value:
                        "Balansi dafalen tɛ, jago ma sɔrɔ, jago tigɛra, session waati tɛmɛna, walima sistɛmu fili. Aw ye saracogo wɛrɛ lajɛ.",
                },
                payment_waiting_1: {
                    value: "N b'aw deli ka kɔnɔni kɛ",
                },
                payment_waiting_2: {
                    value: "Sanni i ka jagokɛcogo sɛgɛsɛgɛli ka kɛ",
                },
                payment_waiting_3: {
                    value: "A bɛ makɔnɔni na ka ɲɛsin validation ma",
                },
                button: {
                    value: "Sara ka bɔ MOOV Money fɛ",
                },
            },
            tresor: {
                text: {
                    value:
                        "Enter your Tresor PAY number and confirm. Then, go to your app on 'Withdrawals' to validate the payment.",
                },
                placeholder: {
                    value: "Your Tresor PAY phone number",
                },
                payment_pending: {
                    value: "In process of verification",
                },
                payment_failure_title: {
                    value: "Payment failed",
                },
                payment_failure_details: {
                    value:
                        "Insufficient balance, Transaction not found, Transaction interrupted, Session expired or system error. Please, try another payment method.",
                },
                payment_waiting_1: {
                    value: "Please wait",
                },
                payment_waiting_2: {
                    value: "Before checking your transaction",
                },
                payment_waiting_3: {
                    value: "Waiting for your validation",
                },
                button: {
                    value: "Pay with Tresor PAY",
                },
                check_status: {
                    value: "Check yourself",
                },
                ou_check_yourself: {
                    value: "OR",
                },
            },
            ussd_apaym: {
                text: {
                    value: "Walisa ka i ka sarako kode sɔrɔ, i ka ",
                },
                text2: {
                    value: " Orange ni MTN kan ani ka cikanw labato.",
                },
                placeholder: {
                    value: "APAYM telefɔni nimɔrɔ",
                },
                placeholder2: {
                    value: "Kode sarali",
                },
                button: {
                    value: "Sara ni APAYM ye",
                },
            },
            apaym: {
                text: {
                    value:
                        "I don i ka APAYM jatebɔsɛbɛn kɔnɔ walasa k’i ka Visa kartiw lajɛ minnu nɔrɔlen bɛ a la. Lupu taamasiyɛn digi walisa ka don a kɔnɔ.",
                },
                placeholder: {
                    value: "APAYM telefɔni nimɔrɔ",
                },
                placeholder2: {
                    value: "Kode donna",
                },
                logout: {
                    value: "Jɛɲɔgɔnya tigɛ",
                },
                choose_card: {
                    value: "I ka i ka karti dɔ digi walasa ka wari sarali tiɲɛ",
                },
                code_sms: {
                    value: "Kode min sɔrɔla SMS fɛ, o don.",
                },
                text_validate_payment_title: {
                    value: "APAYM sarali",
                },
                text_validate_payment: {
                    value: "I ka karti ",
                },
                text_validate_payment2: {
                    value: " bɛna debit ka bɔ ",
                },
                text_validate_payment3: {
                    value: " ka sarali in tiɲɛ.",
                },
                text_validate_payment_yes: {
                    value: "Ɔwɔ, n b'a sɛmɛntiya",
                },
                text_validate_payment_no: {
                    value: "ɔn ɔn",
                },
                sweet_alert_text: {
                    value: "Sarali jɛgɛnsira kopi kɛra!",
                },
                button: {
                    value: "Ka kɔnɛkite",
                },
                captcha: {
                    value: "N b'aw deli ka captcha valide!",
                },
            },
            loyalty_card: {
                value: "Karti kantigiya",
            },
            bonus_offered: {
                value: "Bonus min dira: ",
            },
        },

        update_data: {
            update: {
                value: "[ka korize]",
            },
            update_title: {
                value: "Kunnafoni kura",
            },
            form: {
                number: {
                    value: "Telefɔni nimɔrɔ",
                },
                society: {
                    value: "Trɔn",
                },
                fullname: {
                    value: "Trɔgrɔ ni trɔgrɔ frɔlrɔ",
                },
                s: {
                    value: "",
                },
                email: {
                    value: "E-mail ladɛrɛsi",
                },
                reason: {
                    value: "Sarali kun",
                },
                button: {
                    value: "Ka lakuraya",
                },
            },
        },
    },
};
